import React, { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  withStyles,
  WithStyles,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { Theme, MenuItem, Avatar, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { Typography } from '../../Typography';
import { getRole } from '../../../features/settings/components/profileSettings.view';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      minWidth: 240,
      padding: '10px 24px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    avatarWrapper: {
      gap: 8,
    },
    avatar: {},
    userName: {
      lineHeight: '21px',
      textTransform: 'capitalize',
    },
    userRole: {
      fontSize: '10px',
      lineHeight: '13.6px',
      color: theme.palette.grey[700],
    },
    primaryColorText: {
      background: theme.custom.primaryGradient,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    bottomItem: {
      '& .MuiMenuItem-root': {
        color: theme.palette.grey[600],
      },
    },
    dropdownMenuItem: {
      justifyContent: 'space-between',
      '& .MuiSvgIcon-root': {
        fill: theme.palette.primary.main,
      },
    },
    expandable: {
      height: 0,
      overflow: 'hidden',
    },
    expanded: {
      height: 'auto',
    },
  });
};

export interface IAppBarDrawerProps extends WithStyles<typeof styles> {
  menuOptions: any;
  signOut: () => void;
  auth: any;
  meetingMenuList: any;
}

const AppBarDrawer = ({
  classes,
  menuOptions,
  signOut,
  auth,
  meetingMenuList,
}: IAppBarDrawerProps) => {
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isHostMeetingExpand, setIsHostMeetingExpand] = useState(false);

  const { user, permissions } = auth;

  const filterFirstName = (fullName: string) => {
    const firstName = fullName?.split(' ')[0];
    return firstName;
  };

  return (
    <div className={classes.root}>
      <div>
        <MenuItem className={classes.avatarWrapper} disableGutters>
          <Avatar
            className={classes.avatar}
            alt={user?.profile?.name}
            src={user?.profile?.picture}
          />
          <div>
            <Typography variant="body1" className={classes.userName}>
              {filterFirstName(user?.profile?.name)}
            </Typography>
            <Typography variant="body2" className={classes.userRole}>
              {intl.formatMessage({
                id: getRole(user?.role),
              })}
            </Typography>
          </div>
        </MenuItem>

        {(permissions.scheduleMeeting || permissions.instantMeeting) && (
          <MenuItem
            disableGutters
            className={clsx(classes.primaryColorText, classes.dropdownMenuItem)}
            onClick={() => setIsHostMeetingExpand(!isHostMeetingExpand)}
          >
            <FormattedMessage id="host_meeting" />
            {isHostMeetingExpand ? (
              <ExpandLess fontSize="small" color="inherit" />
            ) : (
              <ExpandMore fontSize="small" color="inherit" />
            )}
          </MenuItem>
        )}
        <div
          className={clsx(
            classes.expandable,
            isHostMeetingExpand && classes.expanded
          )}
        >
          {meetingMenuList.map((item: any, index: any) => (
            <MenuItem onClick={item.onClick} key={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </div>

        {menuOptions?.map((item: any, index: any) => {
          return (
            <MenuItem
              onClick={() => item?.onClick()}
              key={item?.id}
              disableGutters
            >
              {item?.name}
            </MenuItem>
          );
        })}

        {permissions.viewReports && (
          <MenuItem
            disableGutters
            onClick={() => history.push('/reports/meetings')}
            id="meeting-reports"
          >
            <FormattedMessage id="meeting_reports" />
          </MenuItem>
        )}

        <MenuItem
          onClick={() => history.push('/contact-us')}
          key="contact-us"
          disableGutters
        >
          <FormattedMessage id="contact_us" />
        </MenuItem>
        <MenuItem
          onClick={() => signOut()}
          key="sign-out"
          disableGutters
          className={classes.primaryColorText}
        >
          <FormattedMessage id="sign_out" />
        </MenuItem>
      </div>
      <div className={classes.bottomItem}>
        <Divider />
        <MenuItem
          onClick={() => history.push('/terms-of-use')}
          key="terms-of-use"
          disableGutters
        >
          <FormattedMessage id="terms" />
        </MenuItem>
        <MenuItem
          onClick={() => history.push('/privacy-policy')}
          key="privacy-policy"
          disableGutters
        >
          <FormattedMessage id="privacy_policy" />
        </MenuItem>
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(AppBarDrawer));
