import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
    },
    mainWrapper: {},
    leftWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '36px',
      height: '100%',
      [theme.breakpoints.down('lg')]: {
        gap: '20px',
        height: 'auto',
      },
    },
    rightWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    primaryColorText: {
      background: theme.custom.primaryGradient,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    profileCard: {
      padding: '40px',
      display: 'grid',
      gap: '40px',
      minHeight: 230,
      gridTemplateAreas: `"avatar name name"
      "avatar stats stats"`,
      [theme.breakpoints.down('lg')]: {
        padding: '24px',
        gap: '24px',
        minHeight: 190,
      },
      [theme.breakpoints.down('md')]: {
        padding: '20px',
        gap: '15px',
        minHeight: 150,
      },
      [theme.breakpoints.down('xs')]: {
        padding: '24px',
        gap: '10px',
        gridTemplateAreas: `"avatar name name"
      "stats stats stats"`,
      },
    },
    profilePicWrapper: {
      gridArea: 'avatar',
    },
    avatar: {
      height: '156px',
      width: '156px',
      [theme.breakpoints.down('lg')]: {
        height: '100px',
        width: '100px',
      },
      [theme.breakpoints.down('md')]: {
        height: '70px',
        width: '70px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '48px',
        width: '48px',
      },
    },
    // profileDetailsWrapper: {
    //   display: 'flex',
    //   flexDirection: 'column',
    //   justifyContent: 'center',
    // },
    profileNameWrapper: {
      gridArea: 'name',
    },
    profileName: {
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        lineHeight: '22px',
      },
    },
    profileTodayMeeting: {
      fontSize: '36px',
      fontWeight: 300,
      fontFamily: 'Open Sans',
      lineHeight: '49.03px',
      marginTop: '4px',
      color: theme.palette.secondary.main,
      [theme.breakpoints.down('lg')]: {
        fontSize: '32px',
        lineHeight: '35px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '24px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        lineHeight: '22px',
      },
    },
    profileStats: {
      gridArea: 'stats',
      display: 'flex',
      gap: '38px',
      color: theme.palette.secondary.main,
      [theme.breakpoints.down('md')]: {
        gap: '20px',
      },
      [theme.breakpoints.down('xs')]: {
        gap: '10px',
        flexDirection: 'column',
      },
    },
    profileStatsList: {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        marginLeft: 10,
      },
      [theme.breakpoints.down('md')]: {
        '& p': {
          marginLeft: 7,
        },
        '& .MuiSvgIcon-root': {
          fontSize: 18,
        },
        '& .MuiTypography-root': {
          fontSize: 14,
        },
      },
      [theme.breakpoints.down('xs')]: {
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
        '& .MuiTypography-root': {
          fontSize: 14,
          lineHeight: '19px',
        },
      },
    },
    profileStatmargin: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
    },

    leftBottommMain: {
      gap: '36px',
      flexGrow: 1,
      flexWrap: 'nowrap',
      overflow: 'auto',
      [theme.breakpoints.down('md')]: {
        gap: '15px',
      },
      [theme.breakpoints.down('xs')]: {
        gap: '24px',
        flexWrap: 'wrap',
        flexGrow: 0,
      },
    },
    leftBottom: {
      overflow: 'auto',
      // height: '100%',
      maxHeight: '800px',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '50vh',
      },
    },
    fullHeight: {
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },
    tabViewWrapper: {
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },
    tabView: {
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },
    leftBottomRighWrapper: {
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        // display: 'none',
        maxHeight: '60vh',
        overflow: 'auto',
      },
    },
    recentMeetingsWrapper: {
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        overflow: 'auto',
      },
    },
    recentMeetingsTable: {
      position: 'absolute',
      width: 'calc(100% - 24px)',
      top: theme.spacing(1),
      bottom: theme.spacing(1),
      overflow: 'auto',
      [theme.breakpoints.down('xs')]: {
        position: 'relative',
      },
    },
    speedDialWrapper: {
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },
    speedDialTable: {
      // width: 'calc(100% - 24px)',
      overflow: 'auto',
      height: '100%',
      '& .MuiTableCell-root': {
        textTransform: 'capitalize',
      },
      '& .MuiToolbar-root': {
        background: theme.custom.primaryGradient,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },

      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: '#FF0000',
          },
        },
      },
    },
    speedDialInfoIcon: {
      fontSize: '16px',
      color: theme.palette.info.main,
    },
    upCommingMeeting: {
      flexGrow: 1,
      overflow: 'auto',
      width: '100%',
    },
    noUpComming: {
      // backgroundColor: '#F9F9F9',
    },
    blueCard: {
      height: 'auto',
      padding: '1vw',
      color: theme.palette.primary.contrastText,
      marginBottom: '1vh',
      background: `linear-gradient(115deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 100%)`,
    },
    greenCard: {
      background: '#28A745',
    },
    grayCard: {
      background: '#CCCCCC',
    },
    blueCardMeetingDetails: {
      marginTop: 5,
    },
    blueCardMeetingStatsItem: {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        marginLeft: 10,
      },
    },
    blueCardMeetingStatsIcon: {
      width: theme.typography.body2.fontSize,
      height: theme.typography.body2.fontSize,
    },
    blueCardRighWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    blueCardButtonWrapper: {
      display: 'flex',
    },
    blueCardButton: {
      '& .basic-button-class': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        padding: '7px 10px',
        fontSize: '.7rem',
        marginLeft: 5,
        borderColor: 'transparent',
        '&:hover': {
          color: theme.palette.primary.contrastText,
        },
      },
    },
    createMeetingIcons: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: '1vw',
      borderRadius: 20,
      width: '5vw',
      height: '5vw',
      [theme.breakpoints.down('xs')]: {
        width: '16vw',
        height: '16vw',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 50,
        maxHeight: 50,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '& img': {
        width: '100%',
      },
      '& .MuiSvgIcon-fontSizeLarge': {
        fontSize: '3rem',
      },
    },
    rejoinButton: {
      padding: 0,
      '& .MuiButtonBase-root': {
        padding: 0,
        textAlign: 'start',
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
      '& .MuiButton-label': {
        display: 'block',
      },
    },
    speedDialTitle: {
      marginBottom: '1.2rem',
    },
    scrollableContainer: {
      borderRadius: 8,
      backgroundColor: 'white',
      // height: '30vh',
      overflowY: 'auto',
      padding: '1rem',
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      gap: '64px',
      [theme.breakpoints.down('lg')]: {
        gap: '16px',
      },
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '24px',
      },
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.secondary.main,
        textTransform: 'capitalize',
      },
      '& .MuiTypography-body1': {
        marginTop: '1vh',
      },
      '& .MuiButton-root:hover': {
        backgroundColor: 'transparent',
      },
    },

    customLargeButton: {
      padding: 0,
      minWidth: '40px',
      [theme.breakpoints.down('xs')]: {
        '& .MuiTypography-root': {
          fontSize: 14,
        },
      },
    },
    customLargeButtonText: {
      // fontSize: theme.typography.h3.fontSize,
    },
    customLargeButtonIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '15px',
      width: '100%',
      height: 'auto',
      aspectRatio: '1/1',
      marginBottom: '16px',
      '& img': {
        width: '60%',
      },
      [theme.breakpoints.down('lg')]: {
        width: '3.5vw',
        minWidth: 45,
        marginBottom: '6px',

        '& .MuiTypography-root': {
          fontSize: 14,
        },
      },
      [theme.breakpoints.down('xs')]: {
        padding: '10px',
        display: 'flex',

        '& .MuiTypography-root': {
          fontSize: 14,
        },
        '& img': {
          width: '100%',
        },
      },
    },
    customLargeButtonprimary: {
      backgroundImage: theme.custom.primaryGradient,
    },
    customLargeButtonSecondary: {
      backgroundColor: theme.palette.secondary.main,
    },
    customLargeButtonBlue: {
      backgroundColor: theme.palette.info.main,
    },
  });
};

export default styles;
