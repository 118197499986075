import React, { memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useIntl } from 'react-intl';
import {
  withStyles,
  WithStyles,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import RecentMeetingCard from './recentMeetingCard';
import UpcomingMeetingCard from './upcomingMeetingCard';
import OngoingMeetingCard from './ongoingMeetingCard';
import { TableSearch } from '../../../components/Search';
import { IRequestParams } from '../../../components/types';
import { IconButton } from '../../../components/Button';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    appbar: {
      backgroundColor: theme.palette.grey[100],
      boxShadow: 'none',
      borderRadius: 8,
      zIndex: 100,
    },
    tabsHeader: {
      color: theme.palette.secondary.main,
      minHeight: 'unset',

      '& .MuiTab-root': {
        textTransform: 'capitalize',
        fontFamily: 'PT Sans',
        fontSize: theme.typography.h6.fontSize,
        minWidth: '80px !important',
        // [theme.breakpoints.down('md')]: {
        //   fontSize: '14px',
        //   minWidth: 'unset',
        // },
        // [theme.breakpoints.down('xs')]: {
        //   fontSize: 12,
        //   minHeight: 36,
        // },
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& .MuiTabs-fixed': {
        padding: '3px',
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },

      '& .Mui-selected': {
        backgroundColor: theme.palette.common.white,
        borderRadius: 6,
        boxShadow: '0px 3px 5px 0px rgba(0,0,0,0.12)',

        '& .MuiTab-wrapper': {
          background: theme.custom.primaryGradient,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontSize: theme.typography.h6.fontSize,
          fontWeight: 700,
          border: 'none !important',
          // [theme.breakpoints.down('md')]: {
          //   fontSize: '14px',
          // },
          // [theme.breakpoints.down('xs')]: {
          //   fontSize: 12,
          // },
        },
      },
    },
    borderRight: {
      '& .MuiTab-wrapper': {
        borderRight: `1px solid ${theme.palette.grey[500]}`,
      },
    },
    borderLeft: {
      '& .MuiTab-wrapper': {
        borderLeft: `1px solid ${theme.palette.grey[500]}`,
      },
    },
    TabPanelContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    tabPanelWrapper: {
      overflow: 'auto',
      flexGrow: 1,
    },
    tabPanel: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    tabPanelHeadar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '40px 0 24px',
      minHeight: 40,
    },
    tabPanelTitle: {
      fontFamily: 'PT Sans',
      background: theme.custom.primaryGradient,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        // fontSize: 14,
      },
    },
    searchButton: {
      padding: 5,
      color: theme.palette.secondary.main,
      '& .MuiSvgIcon-root': {
        width: 24,
        height: 24,
      },
    },
    meetingsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      overflow: 'auto',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '105vh',
      },
    },
    paginationWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingTop: '24px',
    },
    paginationText: {
      marginRight: '26px',
      fontSize: '12px',
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    arrowButton: {
      margin: 0,
      '& .MuiButton-root': {
        minWidth: '40px',
        color: theme.palette.secondary.main,
      },
      '& .Mui-disabled': {
        color: '#00000061',
      },
      '& :hover': {
        backgroundColor: 'unset',
      },
    },
  });
};

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  title: any;
}

interface ITabViewMeetingsProps extends WithStyles<typeof styles> {
  upcomingMeetings: Array<any>;
  ongoingMeetings: Array<any>;
  recentMeetings: Array<any>;
  scheduleMeetingOnClick: () => void;
  joinToMeetingButtonClick: (data: any) => void;
  openEditMeetingModal: (data: any) => void;
  handleSearch: (data: IRequestParams) => void;
  removeMeeting: (id: string) => void;
}

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const TabViewMeetings = ({
  classes,
  upcomingMeetings,
  ongoingMeetings,
  recentMeetings,
  scheduleMeetingOnClick,
  joinToMeetingButtonClick,
  openEditMeetingModal,
  handleSearch,
  removeMeeting,
}: ITabViewMeetingsProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (event: React.ChangeEvent<any>, newValue: any) => {
    setValue(newValue);
    setCurrentPage(1);
    switch (newValue) {
      case 0:
        setTotalItems(recentMeetings?.length);
        break;
      case 1:
        setTotalItems(ongoingMeetings?.length);
        break;
      case 2:
        setTotalItems(upcomingMeetings?.length);
        break;
      default:
        setTotalItems(recentMeetings?.length);
    }
  };

  const [value, setValue] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(recentMeetings?.length);
  const [firstItemIndex, setFirstItemIndex] = useState(0);
  const [lastItemIndex, setLastItemIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(2);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setTotalItems(recentMeetings?.length);
  }, [recentMeetings]);

  useEffect(() => {
    const TabPanelContainer = document.getElementById('tab-panel-container');
    const TabPanelContainerHeight = TabPanelContainer?.offsetHeight || 0;

    // calItemCount = panel height - title height - pagination height) / item height + gap
    const calItemCount = Math.floor((TabPanelContainerHeight - 40 - 56) / 260);

    setItemsPerPage(calItemCount || 1);
  }, [value]);

  useEffect(() => {
    if (totalItems > 0) {
      setFirstItemIndex((currentPage - 1) * itemsPerPage);
      setLastItemIndex(currentPage * itemsPerPage);
    } else {
      setFirstItemIndex(-1);
      setLastItemIndex(0);
    }
  }, [currentPage, totalItems, value, itemsPerPage]);

  const slicedData = (data: any) => {
    const newArray = data.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
    return newArray;
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, title, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        className={classes.tabPanelWrapper}
      >
        {value === index && (
          <div className={classes.tabPanel}>
            <div className={classes.tabPanelHeadar}>
              <Typography
                variant="caption"
                component="h3"
                className={classes.tabPanelTitle}
              >
                {title}
              </Typography>
            </div>
            <div className={classes.meetingsWrapper}>{children}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.tabsHeader}
          aria-label="simple tabs"
          variant="fullWidth"
          centered
        >
          <Tab
            label={intl.formatMessage({
              id: isMobile ? 'recent' : 'recent_meetings',
            })}
            {...a11yProps(0)}
          />
          <Tab
            label={intl.formatMessage({
              id: isMobile ? 'ongoing' : 'ongoing_meetings',
            })}
            {...a11yProps(1)}
            className={clsx(
              value === 0 && classes.borderRight,
              value === 2 && classes.borderLeft
            )}
          />
          <Tab
            label={intl.formatMessage({
              id: isMobile ? 'upcoming' : 'upcoming_meetings',
            })}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <TableSearch handleSearch={handleSearch} />
      <div className={classes.TabPanelContainer} id="tab-panel-container">
        {value === 0 && (
          <TabPanel
            title={intl.formatMessage({
              id: 'recent_meetings',
            })}
            index={0}
          >
            {slicedData(recentMeetings).map(
              (recentMeeting: any, index: any) => {
                return (
                  <RecentMeetingCard
                    key={index}
                    meeting={recentMeeting}
                    openEditMeetingModal={openEditMeetingModal}
                  />
                );
              }
            )}
          </TabPanel>
        )}
        {value === 1 && (
          <TabPanel
            title={intl.formatMessage({
              id: 'ongoing_meetings',
            })}
            index={1}
          >
            {slicedData(ongoingMeetings).map(
              (ongoingMeeting: any, index: any) => {
                return (
                  <OngoingMeetingCard
                    key={index}
                    meeting={ongoingMeeting}
                    joinToMeetingButtonClick={joinToMeetingButtonClick}
                  />
                );
              }
            )}
          </TabPanel>
        )}
        {value === 2 && (
          <TabPanel
            title={intl.formatMessage({
              id: 'upcoming_meetings',
            })}
            index={2}
          >
            {slicedData(upcomingMeetings).map(
              (upcomingMeeting: any, index: any) => {
                return (
                  <UpcomingMeetingCard
                    key={index}
                    meeting={upcomingMeeting}
                    joinToMeetingButtonClick={joinToMeetingButtonClick}
                    removeMeeting={removeMeeting}
                  />
                );
              }
            )}
          </TabPanel>
        )}
        <div className={classes.paginationWrapper}>
          <div className={classes.paginationText}>
            {itemsPerPage < 2
              ? firstItemIndex + 1
              : `${firstItemIndex + 1} - ${lastItemIndex}`}{' '}
            of {totalItems}
          </div>
          <div className={classes.buttonWrapper}>
            <IconButton
              id="ffff"
              icon={<ArrowBackIosIcon />}
              variant="text"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={firstItemIndex === 0 || !totalItems}
              customStyles={classes.arrowButton}
            />
            <IconButton
              id="ffff"
              icon={<ArrowForwardIosIcon />}
              variant="text"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={totalItems <= lastItemIndex}
              customStyles={classes.arrowButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(TabViewMeetings));
