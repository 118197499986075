import React, { memo, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Grid, Avatar } from '@material-ui/core';
import styles from './livestreamingRoom.styles';
import { Button } from '../../components/Button';
import { Typography } from '../../components/Typography';
import { TextField } from '../../components/TextField';
import { Card } from '../../components/Card';
import { validateEmail } from '../../helper/validation';
import { Clock } from '../../components/Clock';
// import { LoadingLayout } from '../../components/layout';
import { useAppDispatch } from '../../app/hooks';
import { actions } from './livestreamingRoom.slice';

interface ILivestreamingRoomView extends WithStyles<typeof styles> {
  roomName: string;
  viewingUrl: string;
  roomTitle: string;
  user: any;
  backToHomeButtonOnClick: () => void;
}

const LivestreamingRoomView: React.FC<ILivestreamingRoomView> = ({
  classes,
  roomName = '',
  viewingUrl = '',
  roomTitle = '',
  user = null,
  backToHomeButtonOnClick,
}: ILivestreamingRoomView) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLobby, setIsLobby] = useState<boolean>(true);
  const [conferenceLeft, setConferenceLeft] = useState<boolean>(false);
  const [name, setName] = useState<any>({ value: '', error: false });
  const [email, setEmail] = useState<any>({ value: '', error: false });
  const intl = useIntl();

  useEffect(() => {
    if (user?.email && user?.profile?.name) {
      setName({ value: user?.profile?.name, error: false });
      setEmail({ value: user?.email, error: false });
      // when authenticated user arrives, set skip join page to true
      // setSkipJoinPage(true);
    }
  }, [user]);

  useEffect(() => {
    setIsLobby(false);
    setConferenceLeft(false);
    setIsLoading(true);
    dispatch(actions.resetSuccess());
  }, []);

  const _backToHomeButtonOnClick = () => {
    backToHomeButtonOnClick();
  };

  const _rejoinButtonOnClick = () => {
    setIsLobby(false);
    setConferenceLeft(false);
    setIsLoading(true);
  };

  const _nameOnChange = (e: { target: { value: string } }) => {
    setName({ value: e.target.value, error: false });
  };

  const _emailOnChange = (e: { target: { value: string } }) => {
    const error = validateEmail(e.target.value);
    setEmail({ value: e.target.value, error });
  };

  // const _validate = () => {
  //   let isValidate = true;
  //   if (email.error) {
  //     isValidate = false;
  //     setEmail({ ...email, error: true });
  //   }
  //   return isValidate;
  // };

  const _getJwtTokenButtonOnClick = () => {
    // if (_validate()) {
    //   const data = {
    //     roomId: '',
    //     roomName,
    //     userName: name?.value,
    //     userEmail: email?.value,
    //   };
    // }
  };

  const _renderConferenceLeftView = () => (
    <>
      <Card customStyles={classes.bannerCard}>
        <Clock id="lobby-clock" />
      </Card>
      <Card customStyles={classes.useInfoCard}>
        <Typography variant="h5" className={classes.cardHeading}>
          Thank you for joining with us!
        </Typography>
        {(email?.value || name?.value) && (
          <>
            <Typography variant="body2" color="textSecondary">
              You logged in as a
            </Typography>
            <Grid container className={classes.profileDetailsWrapper}>
              <Grid xs={3}>
                <Avatar alt="Remy Sharp" />
              </Grid>
              <Grid>
                <Typography variant="body2">{name?.value}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {email?.value}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        <div className={classes.buttonWrapper}>
          <Button
            id="rejoin-button-in-disconnect"
            variant="outlined"
            color="primary"
            label={intl.formatMessage({
              id: 'rejoin',
            })}
            customStyles={classes.rejoin}
            onClick={_rejoinButtonOnClick}
          />
          <Button
            id="back-to-home-in-disconnect"
            variant="contained"
            color="primary"
            label={intl.formatMessage({
              id: 'back_to_home',
            })}
            onClick={_backToHomeButtonOnClick}
          />
        </div>
      </Card>
    </>
  );
  const _renderCollectUserInfoView = () => (
    <>
      <Card customStyles={classes.bannerCard}>
        <Clock id="lobby-clock" />
      </Card>
      <Card customStyles={classes.useInfoCard}>
        <Typography
          component="h1"
          variant="h5"
          className={classes.textOverflow}
        >
          {roomTitle}
        </Typography>
        <TextField
          label={intl.formatMessage({
            id: 'name',
          })}
          value={name.value}
          onChange={_nameOnChange}
        />
        <TextField
          label={intl.formatMessage({
            id: 'email',
          })}
          value={email.value}
          onChange={_emailOnChange}
          error={email.error}
          helperText={
            email.error &&
            intl.formatMessage({
              id: 'please_insert_valid_email',
            })
          }
        />
        <Button
          id="get-jwt-token-button"
          variant="contained"
          label={intl.formatMessage({
            id: 'join',
          })}
          onClick={_getJwtTokenButtonOnClick}
        />
      </Card>
    </>
  );

  const _renderLobby = () => (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={4} className={classes.leftGrid}>
        {/* <Card>ssdsd</Card> */}
        {conferenceLeft
          ? _renderConferenceLeftView()
          : _renderCollectUserInfoView()}
      </Grid>
    </Grid>
  );

  const _renderConferene = () => (
    <>
      {/* {isLoading && <LoadingLayout />} */}
      {viewingUrl && (
        <div className={classes.conferenceContainer}>
          <ReactPlayer
            url={viewingUrl}
            width={window.innerWidth}
            height={window.innerHeight}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 1,
                  autoplay: 0,
                  controls: 1,
                  loop: 1,
                  fs: 1,
                  rel: 0,
                },
              },
            }}
          />
        </div>
      )}
    </>
  );

  return <>{isLobby ? _renderLobby() : _renderConferene()}</>;
};

export default memo(withStyles(styles)(LivestreamingRoomView));
