/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import React, { memo, useState, useEffect, ChangeEvent } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Grid, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { AutocompleteChangeReason } from '@material-ui/lab';
import { ActionModal } from '../../../components/Modal';
import { TextField } from '../../../components/TextField';
import { TimePicker } from '../../../components/TimePicker';
import { Button } from '../../../components/Button';
import { generateRoomWithoutSeparator } from '../../../helper/random';
import { isLowercaseAlphanumeric } from '../../../helper/validation';
import { replaceRoomNameWithTitle } from '../../../app/config';
import { IScheduledMeetingData } from '../scheduleMeeting.api';
import {
  IGetGroupResponse,
  IGroup,
  emptyGroup,
} from '../../group/groups.slice';
import { IUser } from '../../auth/auth.slice';
import { IScheduleMeetings } from '../scheduleMeeting.slice';
import { AutocompleteTextField } from '../../../components/AutocompleteTextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    datePicker: {
      margin: theme.spacing(2, 0),
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    timePicker: {
      margin: theme.spacing(1, 0),
      '& .MuiInputBase-root': {
        color: theme.palette.secondary.main,
      },
      '& .MuiInput-underline:before': {
        borderColor: theme.palette.secondary.main,
      },
    },
    startTime: {
      marginRight: theme.spacing(1),
    },
    endTime: {
      marginLeft: theme.spacing(1),
    },
    select: {
      width: '100%',
      color: '#989898',
      margin: '10px 0',
      '& em': {
        fontStyle: 'unset !important',
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 30px) scale(1)',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
    autocomplete: {
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: '0',
      },
    },
  });
};

export interface IScheduleMeetingModalProps extends WithStyles<typeof styles> {
  open: boolean;
  isEditMeeting: boolean;
  meeting: IScheduleMeetings;
  groups: IGetGroupResponse;
  membersList: IUser[];
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  saveMeetingButtonClick: (data: IScheduledMeetingData) => void;
}

const ScheduleMeetingModal = ({
  classes,
  open,
  isEditMeeting,
  meeting,
  groups,
  membersList,
  handleClose,
  saveMeetingButtonClick,
}: IScheduleMeetingModalProps) => {
  const intl = useIntl();
  const [meetingName, setMeetingName] = useState({ value: '', error: '' });
  const [startTime, setStartTime] = useState({
    value: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
    error: '',
  });
  const endDate = new Date();
  endDate.setTime(endDate.getTime() + 60 * 60 * 1000);
  const [endTime, setEndTime] = useState({
    value: moment(endDate).format('YYYY-MM-DDTHH:mm'),
    error: '',
  });

  interface IMemberState {
    error: boolean;
    value: IUser[];
  }

  const [members, setMembers] = useState<IMemberState>({
    error: false,
    value: [],
  });
  const [location, setLocation] = useState({ value: '', error: '' });
  const [group, setGroup] = useState({
    value: {
      ...emptyGroup,
      name: '',
    },
    error: '',
  });

  useEffect(() => {
    if (isEditMeeting && meeting) {
      const startTimeError =
        new Date() > new Date(meeting?.beginTime)
          ? intl.formatMessage({
              id: 'enter_valid_date_time',
            })
          : '';
      const endTimeError =
        new Date() > new Date(meeting?.endTime)
          ? intl.formatMessage({
              id: 'enter_valid_date_time',
            })
          : '';

      setMeetingName({ value: meeting?.name, error: '' });
      setLocation({ value: meeting?.location, error: '' });
      setStartTime({
        value: moment(meeting?.beginTime).format('YYYY-MM-DDTHH:mm'),
        error: startTimeError,
      });
      setEndTime({
        value: moment(meeting?.endTime).format('YYYY-MM-DDTHH:mm'),
        error: endTimeError,
      });
      if (meeting?.groupId) setGroup({ value: meeting.groupId, error: '' });
      const setMembersValueArr = [];
      for (const member1 of meeting?.members) {
        for (const member2 of membersList) {
          if (member1.userId._id === member2._id) {
            setMembersValueArr.push(member2);
          }
        }
      }
      setMembers({
        value: setMembersValueArr,
        error: setMembersValueArr.length === 0,
      });
    }
  }, [isEditMeeting, membersList, meeting]);

  const _handleNameChange = (e: { target: { value: string } }) => {
    if (replaceRoomNameWithTitle) {
      setMeetingName({ value: e.target.value, error: '' });
      setLocation({ value: e.target.value, error: '' });
    } else if (!replaceRoomNameWithTitle) {
      setMeetingName({ value: e.target.value, error: '' });
    }
  };

  const _handleLocationChange = (e: { target: { value: string } }) => {
    if (isLowercaseAlphanumeric(e.target.value))
      setLocation({ value: e.target.value, error: '' });
  };

  const _generateRoomName = () => {
    const roomName = generateRoomWithoutSeparator();
    setLocation({ value: roomName, error: '' });
  };

  const _handleStartTimeChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (new Date() <= new Date(e.target.value)) {
      setStartTime({ value: e.target.value, error: '' });
      const date = new Date(e.target.value);
      date.setTime(date.getTime() + 60 * 60 * 1000); // set end time 1 hour after start time
      setEndTime({ value: moment(date).format('YYYY-MM-DDTHH:mm'), error: '' });
    } else
      setStartTime({
        value: e.target.value,
        error: intl.formatMessage({
          id: 'enter_valid_date_time',
        }),
      });
  };

  const handleMemberChange = (
    event: ChangeEvent<unknown>,
    value: IUser[],
    reason: AutocompleteChangeReason
  ) => {
    setMembers({
      value,
      error: value.length === 0,
    });
  };

  const _handleEndTimeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (new Date() <= new Date(e.target.value))
      setEndTime({ value: e.target.value, error: '' });
    else
      setEndTime({
        value: e.target.value,
        error: intl.formatMessage({
          id: 'enter_valid_date_time',
        }),
      });
  };

  // const _handleParticipantsChange = (e: any) => {
  //   setParticipants({ value: e.target.value, error: '' });
  // };

  const handleGroupChange = (
    event: ChangeEvent<unknown>,
    value: IGroup | null,
    reason: AutocompleteChangeReason
  ) => {
    if (value) {
      setGroup({
        value,
        error: '',
      });
    } else {
      setGroup({
        value: {
          ...emptyGroup,
          name: '',
        },
        error: '',
      });
    }
  };

  const _validate = () => {
    let isValidate = true;
    if (!meetingName.value) {
      isValidate = false;
      setMeetingName({
        ...meetingName,
        error: intl.formatMessage({
          id: 'meeting_name_required',
        }),
      });
    }

    if (startTime.error || endTime.error) isValidate = false;

    if (new Date(startTime.value) > new Date(endTime.value)) {
      isValidate = false;
      setEndTime({
        ...endTime,
        error: intl.formatMessage({
          id: 'end_time_cannot_be_before_begin_time',
        }),
      });
    }

    // if (!group.value && (members.value.length === 0 || members.error)) {
    //   isValidate = false;
    //   setmembers({ ...members, error: true });
    //   setGroup({
    //     ...group,
    //     error: intl.formatMessage({
    //       id: 'groupid_is_required',
    //     }),
    //   });
    // }

    return isValidate;
  };

  const _saveMeetingButtonClick = () => {
    if (_validate()) {
      const memberIdsArr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const member of members.value) {
        memberIdsArr.push(member._id);
      }
      let data: IScheduledMeetingData = {
        name: meetingName.value,
        beginTime: new Date(startTime.value),
        endTime: new Date(endTime.value),
        participantCount: 0,
        location: location.value,
        groupId: group.value._id || null,
        deviceId: null,
        members: memberIdsArr,
      };

      if (isEditMeeting) data = { ...data, id: meeting?._id };

      saveMeetingButtonClick(data);
    }
  };

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'schedule_meeting',
      })}
      modalDescription={intl.formatMessage({
        id: 'schedule_meeting_description',
      })}
      id="schedule-meeting-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={_saveMeetingButtonClick}
      actinButtonLabel={intl.formatMessage({
        id: 'save_meeting',
      })}
    >
      <TextField
        placeholder={intl.formatMessage({
          id: 'add_meeting_title',
        })}
        onChange={_handleNameChange}
        value={meetingName.value}
        required
        error={Boolean(meetingName.error)}
        helperText={meetingName.error}
      />
      {!replaceRoomNameWithTitle && (
        <TextField
          placeholder="Add room name"
          onChange={_handleLocationChange}
          value={location.value}
          error={Boolean(location.error)}
          helperText={location.error}
          disabled={isEditMeeting}
        />
      )}
      {!replaceRoomNameWithTitle && !isEditMeeting && (
        <Button
          color="primary"
          id="auto generate meeting-room"
          variant="text"
          label="Or generate meeting name"
          onClick={_generateRoomName}
          // customStyles={classes.tableActionButton}
        />
      )}
      <Grid container className={classes.timePicker}>
        <Grid item xs={6}>
          <TimePicker
            customStyles={classes.startTime}
            label={intl.formatMessage({
              id: 'start_time',
            })}
            type="datetime-local"
            id="schedule-meeting-start-time"
            onChange={(e) => _handleStartTimeChange(e)}
            value={startTime.value}
            error={Boolean(startTime.error)}
            helperText={startTime.error}
          />
        </Grid>
        <Grid item xs={6}>
          <TimePicker
            customStyles={classes.endTime}
            label={intl.formatMessage({
              id: 'end_time',
            })}
            type="datetime-local"
            id="schedule-meeting-end-time"
            onChange={(e) => _handleEndTimeChange(e)}
            value={endTime.value}
            error={Boolean(endTime.error)}
            helperText={endTime.error}
          />
        </Grid>
      </Grid>
      <AutocompleteTextField
        multiple={false}
        limitTags={1}
        id="schedule-meeting-groups"
        options={groups.data}
        getOptionLabel={(option) => option.name}
        value={group.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={handleGroupChange}
        label={intl.formatMessage({
          id: 'group',
        })}
        placeholder={intl.formatMessage({
          id: 'select_a_group',
        })}
      />
      <AutocompleteTextField
        multiple
        limitTags={2}
        id="schedule-meeting-members"
        options={membersList}
        getOptionLabel={(option) => option.profile.name}
        value={members.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={handleMemberChange}
        label={intl.formatMessage({
          id: 'members',
        })}
        placeholder={intl.formatMessage({
          id: 'select_at_least_one_member',
        })}
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(ScheduleMeetingModal));
