import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { getMeetingByLocationApi } from './livestreamingRoom.api';
import { actions as appActions } from '../app/app.slice';

export interface ILivestreamingRoomState {
  roomName: string;
  viewingUrl: string;
  loading: boolean;
  success: boolean;
  error: any;
}

const initialState: ILivestreamingRoomState = {
  roomName: '',
  viewingUrl: '',
  loading: false,
  success: false,
  error: null,
};

export const getMeetingByLocationAsync = createAsyncThunk(
  'getMeetingByLocation',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await getMeetingByLocationApi(data);
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const livestreamingRoomSlice = createSlice({
  name: 'scheduleMeeting',
  initialState,
  reducers: {
    resetSuccess(state) {
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    // get Meeting Token
    builder.addCase(getMeetingByLocationAsync.pending, (state, action) => {
      state.roomName = '';
    });
    builder.addCase(getMeetingByLocationAsync.fulfilled, (state, action) => {
      state.roomName = action.payload?.data?.name;
      state.viewingUrl = action.payload?.data?.livestreaming?.viewingUrl;
    });
    builder.addCase(getMeetingByLocationAsync.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const { actions } = livestreamingRoomSlice;

export const selectLivestreamingRoom = (state: RootState) =>
  state.livestreamingRoom;

export default livestreamingRoomSlice.reducer;
