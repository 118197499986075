/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LivestreamingView from './livestreaming.view';
import {
  connectYoutubeAsync,
  disconnectYoutubeAsync,
  readLivestreamingsAsync,
  getYoutubeStatusAsync,
  selectLivestreaming,
  actions as livestreamingActions,
  deleteLivestreamingAsync,
} from './livestreaming.slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAuth } from '../auth/auth.slice';
import {
  generateLivestreamingUrl,
  generateMeetingUrl,
} from '../../helper/conference';
import { actions as appActions } from '../app/app.slice';
import { SchedulLiveStreamingModal } from './component/scheduleLiveStreaming';
import { IRequestParams } from '../../components/types';

const LivestreamingContainer: React.FC<unknown> = () => {
  const dispatch = useAppDispatch();
  const livestreaming = useAppSelector(selectLivestreaming);
  const auth = useAppSelector(selectAuth);
  const history = useHistory();

  const [upcomingMeetings, setUpcomingMeetings] = useState<Array<any>>([]);
  const [ongoingMeetings, setOngoingMeetings] = useState<Array<any>>([]);
  const [recentMeetings, setRecentMeetings] = useState<Array<any>>([]);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10122'));
  }, []);

  useEffect(() => {
    if (auth?.user?.role !== 'member') {
      dispatch(getYoutubeStatusAsync());
    }
  }, []);

  useEffect(() => {
    dispatch(readLivestreamingsAsync(null));
  }, []);

  useEffect(() => {
    const now = new Date().toISOString();

    const past = livestreaming?.list
      ?.filter(({ beginTime }) => beginTime <= now)
      .sort((a, b) => (a.beginTime - b.beginTime ? 1 : -1));
    const all = livestreaming?.list?.map((obj) => {
      let meetingType;
      if (obj.beginTime >= now) {
        meetingType = 'upcoming';
      } else if (obj.beginTime <= now && obj.endTime >= now) {
        meetingType = 'ongoing';
      } else {
        meetingType = 'past';
      }
      return {
        ...obj,
        meetingType,
      };
    });

    const ongoingMeetingsList = all
      ?.filter(
        (meeting) =>
          meeting.meetingType === 'ongoing' && meeting.type !== 'instant'
      )
      .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));
    const upcomingMeetingsList = all
      ?.filter(
        (meeting) =>
          meeting.meetingType === 'upcoming' && meeting.type !== 'instant'
      )
      .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));

    setRecentMeetings(past);
    setUpcomingMeetings(upcomingMeetingsList);
    setOngoingMeetings(ongoingMeetingsList);
  }, [livestreaming.list]);

  const _scheduleMeetingOnClick = () => {
    dispatch(livestreamingActions.openCreateLivestreamingModal());
  };

  const _joinToMeetingButtonClick = (location: string, userType: string) => {
    let url = generateMeetingUrl(location, false);
    if (userType === 'viewer') {
      url = generateLivestreamingUrl(location, false);
    }
    history.push(url);
  };

  const _handleSearch = (data: IRequestParams) => {
    dispatch(readLivestreamingsAsync(data));
  };

  const _openUpdateLivestreamingModal = (data: any) => {
    dispatch(livestreamingActions.openUpdateLivestreamingModal(data));
  };

  const _openViewLivestreamingModal = (data: any) => {
    dispatch(livestreamingActions.openViewLivestreamingModal(data));
  };

  const _removeMeeting = (id: string) => {
    dispatch(deleteLivestreamingAsync(id));
  };

  const connectYoutube = () => {
    dispatch(connectYoutubeAsync());
  };
  const disconnectYoutube = () => {
    dispatch(disconnectYoutubeAsync());
  };
  return (
    <>
      <LivestreamingView
        selectStream={livestreaming}
        upcomingMeetings={upcomingMeetings}
        ongoingMeetings={ongoingMeetings}
        recentMeetings={recentMeetings}
        permissions={auth.permissions}
        connectYoutube={connectYoutube}
        disconnectYoutube={disconnectYoutube}
        removeMeeting={_removeMeeting}
        joinToMeetingButtonClick={_joinToMeetingButtonClick}
        scheduleMeetingOnClick={_scheduleMeetingOnClick}
        handleSearch={_handleSearch}
        openUpdateLivestreamingModal={_openUpdateLivestreamingModal}
        openViewLivestreamingModal={_openViewLivestreamingModal}
      />

      <SchedulLiveStreamingModal />
    </>
  );
};

export default memo(LivestreamingContainer);
