/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import React, { memo, useEffect, useState } from 'react';
// import TagManager from 'react-gtm-module';
// import ReactGA from 'react-ga';
import { IntlProvider } from 'react-intl';
import Routes from './routes';
import { MainLayout } from './components/layout';
import { AppContainer } from './features/app';
import { selectAuth } from './features/auth/auth.slice';
import { useAppSelector } from './app/hooks';
import { LOCALES, messages } from './helper/translation';

const App = () => {
  // const dispatch = useAppDispatch();

  const auth = useAppSelector(selectAuth);

  // const tagManagerArgs = {
  //   gtmId: 'GTM-N8NWRND',
  // };

  let availableBrowserLang = 'en-US';
  navigator.languages.forEach((language) => {
    for (const [key, value] of Object.entries(LOCALES)) {
      if (key === 'ENGLISH') {
        continue;
      }
      if (value.substring(0, 2) === language.substring(0, 2)) {
        availableBrowserLang = value;
        break;
      }
    }
  });
  const [locale, setLocale] = useState(availableBrowserLang);
  const [localeMessages, setLocaleMessages] = useState(
    messages[availableBrowserLang]
  );

  // useEffect(() => {
  //   // dispatch(getProfileAsync(null));
  //   ReactGA.initialize('UA-118777783-1');
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  //   TagManager.initialize(tagManagerArgs);
  // }, []);

  useEffect(() => {
    if (auth && auth.user) {
      if (auth.user.profile.locale) {
        setLocale(auth.user.profile.locale);
        setLocaleMessages(messages[auth.user.profile.locale]);
      }
    }
  }, [auth, auth.user]);
  return (
    <IntlProvider
      messages={localeMessages}
      locale={locale}
      defaultLocale={LOCALES.ENGLISH}
    >
      <MainLayout>
        <AppContainer />
        <Routes />
      </MainLayout>
    </IntlProvider>
  );
};

export default memo(App);
