/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { memo, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Divider, Grid } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './emailRouter.styles';
import { Typography } from '../../components/Typography';
import { PasswordTextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { validatePassword } from '../../helper/validation';

import { Logo as DefaultLogo } from '../../assets/icons';

interface IEmailRouterView extends WithStyles<typeof styles> {
  handleChangeUserPassword: (data: any) => void;
  handleSignin: (data: any) => void;
  generalSettings?: any;
  view: string;
  isPasswordReset: boolean;
}

const EmailRouterView: React.FC<IEmailRouterView> = ({
  classes,
  handleChangeUserPassword,
  handleSignin,
  generalSettings,
  view,
  isPasswordReset,
}: IEmailRouterView) => {
  const intl = useIntl();
  const [password1, setPassword1] = useState({ error: false, value: '' });
  const [password2, setPassword2] = useState({ error: false, value: '' });

  const passwordOnChange1 = (e: { target: { value: string } }) => {
    const error = validatePassword(e.target.value);
    setPassword1({ value: e.target.value, error });
  };
  const passwordOnChange2 = (e: { target: { value: string } }) => {
    const error = validatePassword(e.target.value);
    setPassword2({ value: e.target.value, error });
  };

  const _validate = () => {
    let isValidate = true;

    if (
      !password1.value ||
      password1.error ||
      !password2.value ||
      password2.error
    ) {
      isValidate = false;
    }

    return isValidate;
  };
  const _handleOnClickButton = () => {
    if (_validate()) {
      const userData = {
        password: password1.value,
        confirm: password2.value,
        isPasswordReset,
      };
      handleChangeUserPassword(userData);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={7} className={classes.leftGrid} />
      <Grid item xs={12} sm={5} className={classes.rightGrid}>
        <div className={classes.formWrapper}>
          {view === 'passwordChange' ? (
            <>
              <img
                src={generalSettings?.logo || DefaultLogo}
                alt={`${generalSettings?.companyName}-logo` || 'meetrix-logo'}
                className={classes.logo}
              />
              <Typography
                component="h1"
                variant="h5"
                className={classes.heading}
              >
                <FormattedMessage id="welcome_to_jmap" />
              </Typography>
              <div className={classes.subTextWrapper}>
                <Divider className={classes.subTextDivider} />
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.subText}
                >
                  <FormattedMessage id="please_set_new_password" />
                </Typography>
              </div>
              <PasswordTextField
                id="change-password-input1"
                label={intl.formatMessage({
                  id: 'new_password',
                })}
                placeholder={intl.formatMessage({
                  id: 'new_password',
                })}
                onChange={passwordOnChange1}
                required
                error={password1.error}
                helperText={
                  password1.error &&
                  intl.formatMessage({
                    id: 'please_insert_valid_password',
                  })
                }
              />
              <PasswordTextField
                id="change-password-input2"
                label={intl.formatMessage({
                  id: 'confirm_password',
                })}
                placeholder={intl.formatMessage({
                  id: 'confirm_password',
                })}
                onChange={passwordOnChange2}
                required
                error={password2.error}
                helperText={
                  password2.error &&
                  intl.formatMessage({
                    id: 'please_insert_valid_password',
                  })
                }
              />
              <Button
                id="Proceed-button-in-emailRouter"
                align="right"
                variant="contained"
                label={intl.formatMessage({
                  id: 'proceed',
                })}
                onClick={_handleOnClickButton}
                customStyles={classes.buttonMargin}
              />
            </>
          ) : view === 'tokenExpired' ? (
            <>
              <Typography
                component="h1"
                variant="h5"
                className={classes.heading}
              >
                {isPasswordReset ? (
                  <FormattedMessage id="reset_link_expired" />
                ) : (
                  <FormattedMessage id="invitation_expired" />
                )}
              </Typography>
              <div className={classes.subTextWrapper}>
                <Divider className={classes.subTextDivider} />
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.subText}
                >
                  <FormattedMessage id="please_retry" />
                </Typography>
              </div>
              <Button
                id="sign-in-button"
                align="center"
                variant="contained"
                label={intl.formatMessage({
                  id: 'sign_in',
                })}
                onClick={handleSignin}
                customStyles={classes.buttonMargin}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default memo(withStyles(styles)(EmailRouterView));
