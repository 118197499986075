/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/ban-types */
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { ActionModal } from '../../../components/Modal';
import { TextField } from '../../../components/TextField';
import {
  validateContactNumber,
  validateEmail,
} from '../../../helper/validation';
import { AutocompleteTextField } from '../../../components/AutocompleteTextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IEditMemberModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: any;
  handleEditMember: (data: any) => void;
  selectMember: any;
  selectedMember: any;
}

interface IParentIdState {
  value: string;
  error: boolean;
}

const EditMemberModal = ({
  classes,
  open,
  handleClose,
  handleEditMember,
  selectMember,
  selectedMember,
}: IEditMemberModalProps) => {
  const [autocompleteValue, setAutocompleteValue] = useState<any>({
    _id: 'none',
    profile: { name: 'none' },
  });
  const [parentId, setParentId] = useState<IParentIdState>({
    error: false,
    value: '',
  });

  useEffect(() => {
    handleClose();
  }, [selectMember.loading]);

  useEffect(() => {
    if (selectedMember) {
      setName({
        value: selectedMember.profile.name,
        error: !selectedMember.profile.name,
      });
      setContactNumber({
        value: selectedMember.profile.contactNumber,
        error: !selectedMember.profile.contactNumber,
      });
      setEmail({
        value: selectedMember.email,
        error: !selectedMember.email,
      });
    }
  }, [selectedMember]);

  useEffect(() => {
    const autocompleteManagedByValue = {
      _id: '',
      profile: { name: 'none' },
    };
    setAutocompleteValue(autocompleteManagedByValue);
    setParentId({
      value: autocompleteManagedByValue?._id,
      error: autocompleteManagedByValue?.profile?.name === 'none',
    });
  }, [selectedMember]);

  const [name, setName] = useState({
    error: false,
    value: '',
  });
  const [contactNumber, setContactNumber] = useState({
    error: false,
    value: '',
  });
  const [email, setEmail] = useState({
    error: false,
    value: '',
  });

  const intl = useIntl();

  const _validate = () => {
    let isValidate = true;
    if (!name.value || name.error) {
      isValidate = false;
      setName({ ...name, error: true });
    }
    if (!contactNumber.value || contactNumber.error) {
      isValidate = false;
      setContactNumber({ ...contactNumber, error: true });
    }
    if (!email.value || email.error) {
      isValidate = false;
      setEmail({ ...email, error: true });
    }
    // if (parentId.value === '' || parentId.error) {
    //   isValidate = false;
    //   setParentId({ ...parentId, error: true });
    // }
    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const memberData = {
        id: selectedMember._id,
        name: name.value,
        contactNumber: contactNumber.value,
        email: email.value,
        parentId: parentId.value,
      };
      handleEditMember(memberData);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    setName({
      value: e.target.value,
      error: !e.target.value,
    });
  };

  const contactNumberOnChange = (e: { target: { value: string } }) => {
    const error = validateContactNumber(e.target.value);
    setContactNumber({ value: e.target.value, error });
  };
  const emailOnChange = (e: { target: { value: string } }) => {
    const error = validateEmail(e.target.value);
    setEmail({ value: e.target.value, error });
  };

  const handleAutocompleteChange = (
    event: ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason
  ) => {
    setParentId({
      value: value?._id,
      error: value?._id === undefined,
    });
    setAutocompleteValue(value);
  };

  const getManagedByWithPrefix = (option: any) => {
    if (option?.role === 'subadmin') return `ABU: ${option?.profile?.name}`;
    if (option?.role === 'customer') return `AU: ${option?.profile?.name}`;
    return '';
  };

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'edit_member',
      })}
      id="edit-member-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
      actinButtonLabel={intl.formatMessage({
        id: 'continue',
      })}
    >
      <TextField
        id="edit-member-name"
        value={name.value}
        error={name.error}
        placeholder={intl.formatMessage({
          id: 'name',
        })}
        label={intl.formatMessage({
          id: 'name',
        })}
        onChange={nameOnChange}
        required
        helperText={name.error && 'Please insert a name.'}
      />
      <TextField
        id="edit-member-contact-number"
        value={contactNumber.value}
        error={contactNumber.error}
        placeholder={intl.formatMessage({
          id: 'contact_number',
        })}
        label={intl.formatMessage({
          id: 'contact_number',
        })}
        type="tel"
        onChange={contactNumberOnChange}
        required
        helperText={
          contactNumber.error && 'Please insert a valid contact number.'
        }
      />
      <TextField
        id="edit-member-email"
        value={email.value}
        error={email.error}
        placeholder={intl.formatMessage({
          id: 'email',
        })}
        label={intl.formatMessage({
          id: 'email',
        })}
        onChange={emailOnChange}
        required
        helperText={email.error && 'Please insert a valid email.'}
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(EditMemberModal));
