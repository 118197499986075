import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    meetingCard: {
      // height: '100%',
    },
    title: {
      marginBottom: 16,
      fontWeight: 700,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('xs')]: {
        // fontSize: theme.typography.subtitle1.fontSize,
      },
    },
    meetingInfoWrapper: {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto',
      gap: '16px',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      },
    },
    infoItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',

      [theme.breakpoints.down('xs')]: {
        '& .MuiSvgIcon-root': {
          width: 20,
          height: 20,
        },
      },
    },
    infoItemText: {
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
    largeItem: {
      gridColumnStart: 2,
      gridColumnEnd: 4,
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      gap: '10px',
      marginTop: 24,
      [theme.breakpoints.down('md')]: {
        gap: 8,
      },
    },
  });
};

export default styles;
