import { IntlShape } from 'react-intl';
import { IScheduleMeetings } from '../features/scheduleMeeting/scheduleMeeting.slice';

export const getBooleanString = (intl: IntlShape, boolean: boolean): string => {
  if (boolean) {
    return intl.formatMessage({
      id: 'true',
    });
  }
  return intl.formatMessage({
    id: 'false',
  });
};

export const getMeetingName = (
  intl: IntlShape,
  row: IScheduleMeetings
): string => {
  if (!row.name && row.type === 'instant') {
    return intl.formatMessage({
      id: 'speed_dial',
    });
  }
  return row.name;
};
