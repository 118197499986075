/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import React, { memo, useState, useEffect, ChangeEvent } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Grid, Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { AutocompleteChangeReason } from '@material-ui/lab';
import { ActionModal } from '../../../../components/Modal';
import { TextField } from '../../../../components/TextField';
import { TimePicker } from '../../../../components/TimePicker';
import { Button } from '../../../../components/Button';
import { generateRoomWithoutSeparator } from '../../../../helper/random';
import {
  isLowercaseAlphanumeric,
  validateEmail,
} from '../../../../helper/validation';
import { replaceRoomNameWithTitle } from '../../../../app/config';
import {
  IGetGroupResponse,
  IGroup,
  emptyGroup,
} from '../../../group/groups.slice';
import { IUser } from '../../../auth/auth.slice';
import { IScheduledMeetingData } from '../../livestreaming.api';
import { IScheduleMeetings } from '../../../scheduleMeeting/scheduleMeeting.slice';
import { Tab, TabPanel, Tabs } from '../../../../components/Tabs';
// import { Typography } from '../../../../components/Typography';
import { AutocompleteTextField } from '../../../../components/AutocompleteTextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    datePicker: {
      margin: theme.spacing(2, 0),
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    select: {
      width: '100%',
      color: '#989898',
      margin: '10px 0',
      '& em': {
        fontStyle: 'unset !important',
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 30px) scale(1)',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
    appbar: {
      backgroundColor: theme.palette.grey[100],
      boxShadow: 'none',
      borderRadius: 8,
      zIndex: 100,
      margin: '28px 0',
    },
    borderRight: {
      '& .MuiTab-wrapper': {
        borderRight: `1px solid ${theme.palette.grey[500]}`,
      },
    },
    borderLeft: {
      '& .MuiTab-wrapper': {
        borderLeft: `1px solid ${theme.palette.grey[500]}`,
      },
    },
    autocomplete: {
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: '0',
      },
    },
    streamerDescription: {
      marginTop: '12px',
      height: '103px',
    },
  });
};

export interface IScheduleMeetingModalProps extends WithStyles<typeof styles> {
  open: boolean;
  isUpdateLivestreaming: boolean;
  meeting: IScheduleMeetings;
  groups: IGetGroupResponse;
  membersList: IUser[];
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  saveMeetingButtonClick: (data: IScheduledMeetingData) => void;
  loading: boolean;
  isCreateLiveStreaming: boolean;
  isViewLiveStreaming: boolean;
}

const ScheduleLiveStreamingModal = ({
  classes,
  open,
  isUpdateLivestreaming,
  meeting,
  groups,
  membersList,
  handleClose,
  saveMeetingButtonClick,
  loading,
  isCreateLiveStreaming,
  isViewLiveStreaming,
}: IScheduleMeetingModalProps) => {
  const intl = useIntl();
  const [meetingName, setMeetingName] = useState({ value: '', error: '' });
  const [startTime, setStartTime] = useState({
    value: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
    error: '',
  });
  const endDate = new Date();
  endDate.setTime(endDate.getTime() + 60 * 60 * 1000);
  const [endTime, setEndTime] = useState({
    value: moment(endDate).format('YYYY-MM-DDTHH:mm'),
    error: '',
  });
  const [tabIndex, setTabIndex] = React.useState(0);

  interface IMemberState {
    error: boolean;
    value: IUser[];
  }

  interface IExternalMemberState {
    error: boolean;
    value: string[];
  }

  const [members, setMembers] = useState<IMemberState>({
    error: false,
    value: [],
  });

  const [externalMembers, setExternalMembers] = useState<IExternalMemberState>({
    error: false,
    value: [],
  });

  const [location, setLocation] = useState({ value: '', error: '' });

  const [group, setGroup] = useState({
    value: {
      ...emptyGroup,
      name: '',
    },
    error: '',
  });

  // Streaming viewers
  const [livestreamingGroup, setLivestreamingGroup] = useState({
    value: {
      ...emptyGroup,
      name: '',
    },
    error: '',
  });

  const [
    livestreamingViewers,
    setLivestreamingViewers,
  ] = useState<IMemberState>({
    error: false,
    value: [],
  });

  const [
    livestreamingExternalViewers,
    setLivestreamingExternalViewers,
  ] = useState<IExternalMemberState>({
    error: false,
    value: [],
  });

  useEffect(() => {
    if ((isUpdateLivestreaming || isViewLiveStreaming) && meeting) {
      const startTimeError =
        new Date() > new Date(meeting?.beginTime)
          ? intl.formatMessage({
              id: 'enter_valid_date_time',
            })
          : '';
      const endTimeError =
        new Date() > new Date(meeting?.endTime)
          ? intl.formatMessage({
              id: 'enter_valid_date_time',
            })
          : '';

      setMeetingName({ value: meeting?.name, error: '' });
      setLocation({ value: meeting?.location, error: '' });
      setStartTime({
        value: moment(meeting?.beginTime).format('YYYY-MM-DDTHH:mm'),
        error: startTimeError,
      });
      setEndTime({
        value: moment(meeting?.endTime).format('YYYY-MM-DDTHH:mm'),
        error: endTimeError,
      });
      if (meeting?.groupId) setGroup({ value: meeting.groupId, error: '' });

      const setMembersValueArr = [];
      for (const member1 of meeting?.members) {
        for (const member2 of membersList) {
          if (member1.userId._id === member2._id) {
            setMembersValueArr.push(member2);
          }
        }
      }
      setMembers({
        value: setMembersValueArr,
        error: setMembersValueArr.length === 0,
      });

      const setExternalMembersValueArr = [];
      for (const externalMember of meeting?.externalMembers) {
        setExternalMembersValueArr.push(externalMember.email);
      }
      setExternalMembers({
        value: setExternalMembersValueArr,
        error: setExternalMembersValueArr.length === 0,
      });

      const livestreamingViewersValueArr = [];
      for (const member1 of meeting?.livestreamingViewers) {
        for (const member2 of membersList) {
          if (member1.userId._id === member2._id) {
            livestreamingViewersValueArr.push(member2);
          }
        }
      }
      setLivestreamingViewers({
        value: livestreamingViewersValueArr,
        error: livestreamingViewersValueArr.length === 0,
      });

      const livestreamingExternalViewersValueArr = [];
      for (const livestreamingExternalViewer of meeting?.livestreamingExternalViewers) {
        livestreamingExternalViewersValueArr.push(
          livestreamingExternalViewer.email
        );
      }
      setLivestreamingExternalViewers({
        value: livestreamingExternalViewersValueArr,
        error: livestreamingExternalViewersValueArr.length === 0,
      });

      if (meeting?.livestreaming?.groupId)
        setLivestreamingGroup({
          value: meeting.livestreaming.groupId,
          error: '',
        });
    }
  }, [isUpdateLivestreaming, isViewLiveStreaming, membersList, meeting]);

  const _handleNameChange = (e: { target: { value: string } }) => {
    if (replaceRoomNameWithTitle) {
      setMeetingName({ value: e.target.value, error: '' });
      setLocation({ value: e.target.value, error: '' });
    } else if (!replaceRoomNameWithTitle) {
      setMeetingName({ value: e.target.value, error: '' });
    }
  };

  const _handleLocationChange = (e: { target: { value: string } }) => {
    if (isLowercaseAlphanumeric(e.target.value))
      setLocation({ value: e.target.value, error: '' });
  };

  const _generateRoomName = () => {
    const roomName = generateRoomWithoutSeparator();
    setLocation({ value: roomName, error: '' });
  };

  const _handleStartTimeChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (new Date() <= new Date(e.target.value)) {
      setStartTime({ value: e.target.value, error: '' });
      const date = new Date(e.target.value);
      date.setTime(date.getTime() + 60 * 60 * 1000); // set end time 1 hour after start time
      setEndTime({ value: moment(date).format('YYYY-MM-DDTHH:mm'), error: '' });
    } else
      setStartTime({
        value: e.target.value,
        error: intl.formatMessage({
          id: 'enter_valid_date_time',
        }),
      });
  };

  const handleMemberChange = (
    event: ChangeEvent<unknown>,
    value: IUser[],
    reason: AutocompleteChangeReason
  ) => {
    setMembers({
      value,
      error: value.length === 0,
    });
  };

  const handleExternalMembersChange = (
    event: ChangeEvent<unknown>,
    value: string[],
    reason: AutocompleteChangeReason
  ) => {
    if (value.slice(-1)[0]) {
      if (validateEmail(value.slice(-1)[0])) {
        return;
      }
    }
    setExternalMembers({
      value,
      error: value.length === 0,
    });
  };

  const handleLivestreamingViewersChange = (
    event: ChangeEvent<unknown>,
    value: IUser[],
    reason: AutocompleteChangeReason
  ) => {
    setLivestreamingViewers({
      value,
      error: value.length === 0,
    });
  };

  const handleLivestreamingExternalViewersChange = (
    event: ChangeEvent<unknown>,
    value: string[],
    reason: AutocompleteChangeReason
  ) => {
    if (value.slice(-1)[0]) {
      if (validateEmail(value.slice(-1)[0])) {
        return;
      }
    }
    setLivestreamingExternalViewers({
      value,
      error: value.length === 0,
    });
  };

  const _handleEndTimeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (new Date() <= new Date(e.target.value))
      setEndTime({ value: e.target.value, error: '' });
    else
      setEndTime({
        value: e.target.value,
        error: intl.formatMessage({
          id: 'enter_valid_date_time',
        }),
      });
  };

  // const _handleParticipantsChange = (e: any) => {
  //   setParticipants({ value: e.target.value, error: '' });
  // };

  const handleGroupChange = (
    event: ChangeEvent<unknown>,
    value: IGroup | null,
    reason: AutocompleteChangeReason
  ) => {
    if (value) {
      setGroup({
        value,
        error: '',
      });
    } else {
      setGroup({
        value: {
          ...emptyGroup,
          name: '',
        },
        error: '',
      });
    }
  };

  const handleStreamingGroupChange = (
    event: ChangeEvent<unknown>,
    value: IGroup | null,
    reason: AutocompleteChangeReason
  ) => {
    if (value) {
      setLivestreamingGroup({
        value,
        error: '',
      });
    } else {
      setLivestreamingGroup({
        value: {
          ...emptyGroup,
          name: '',
        },
        error: '',
      });
    }
  };

  const _validate = () => {
    let isValidate = true;
    if (!meetingName.value) {
      isValidate = false;
      setMeetingName({
        ...meetingName,
        error: intl.formatMessage({
          id: 'meeting_name_required',
        }),
      });
    }

    if (startTime.error || endTime.error) isValidate = false;

    if (new Date(startTime.value) > new Date(endTime.value)) {
      isValidate = false;
      setEndTime({
        ...endTime,
        error: intl.formatMessage({
          id: 'end_time_cannot_be_before_begin_time',
        }),
      });
    }

    // if (!group.value && (members.value.length === 0 || members.error)) {
    //   isValidate = false;
    //   setmembers({ ...members, error: true });
    //   setGroup({
    //     ...group,
    //     error: intl.formatMessage({
    //       id: 'groupid_is_required',
    //     }),
    //   });
    // }

    return isValidate;
  };

  const _saveMeetingButtonClick = () => {
    if (_validate()) {
      const memberIdsArr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const member of members.value) {
        memberIdsArr.push(member._id);
      }

      const livestreamingIdsArr = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const livestreamingViewer of livestreamingViewers.value) {
        livestreamingIdsArr.push(livestreamingViewer._id);
      }

      let data: IScheduledMeetingData = {
        name: meetingName.value,
        beginTime: new Date(startTime.value),
        endTime: new Date(endTime.value),
        participantCount: 0,
        location: location.value,
        groupId: group.value._id || null,
        deviceId: null,
        members: memberIdsArr,
        externalMembers: externalMembers.value,
        livestreamingGroupId: livestreamingGroup.value._id || null,
        livestreamingViewers: livestreamingIdsArr,
        livestreamingExternalViewers: livestreamingExternalViewers.value,
      };

      if (isUpdateLivestreaming) data = { ...data, id: meeting?._id };

      saveMeetingButtonClick(data);
    }
  };

  const handleChange = (event: React.ChangeEvent<any>, newValue: any) => {
    setTabIndex(newValue);
  };

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id:
          (isCreateLiveStreaming && 'create_stream') ||
          (isUpdateLivestreaming && 'update_stream') ||
          'view_stream',
      })}
      modalDescription={intl.formatMessage({
        id:
          isUpdateLivestreaming || isCreateLiveStreaming
            ? 'create_stream_description'
            : 'view_stream_description',
      })}
      id="create-stream-modal"
      open={open}
      handleClose={handleClose}
      actinButtonOnClick={_saveMeetingButtonClick}
      actinButtonLabel={intl.formatMessage({
        id: isCreateLiveStreaming ? 'create' : 'save',
      })}
      actionButtonDisabled={
        loading || !(isUpdateLivestreaming || isCreateLiveStreaming)
      }
    >
      <TextField
        placeholder={intl.formatMessage({
          id: 'title',
        })}
        onChange={_handleNameChange}
        value={meetingName.value}
        required
        error={Boolean(meetingName.error)}
        helperText={meetingName.error}
        disabled={!(isUpdateLivestreaming || isCreateLiveStreaming)}
      />
      {!replaceRoomNameWithTitle && (
        <TextField
          placeholder="Add room name"
          onChange={_handleLocationChange}
          value={location.value}
          error={Boolean(location.error)}
          helperText={location.error}
          disabled={!(isUpdateLivestreaming || isCreateLiveStreaming)}
        />
      )}
      {!replaceRoomNameWithTitle &&
        !(isUpdateLivestreaming || isCreateLiveStreaming) && (
          <Button
            color="primary"
            id="auto generate meeting-room"
            variant="text"
            label="Or generate meeting name"
            onClick={_generateRoomName}
            // customStyles={classes.tableActionButton}
          />
        )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TimePicker
            label={intl.formatMessage({
              id: 'start_time',
            })}
            type="datetime-local"
            id="schedule-meeting-start-time"
            onChange={(e) => _handleStartTimeChange(e)}
            value={startTime.value}
            error={isUpdateLivestreaming ? Boolean(startTime.error) : false}
            helperText={isUpdateLivestreaming ? startTime.error : null}
            disabled={!(isUpdateLivestreaming || isCreateLiveStreaming)}
          />
        </Grid>
        <Grid item xs={6}>
          <TimePicker
            label={intl.formatMessage({
              id: 'end_time',
            })}
            type="datetime-local"
            id="schedule-meeting-end-time"
            onChange={(e) => _handleEndTimeChange(e)}
            value={endTime.value}
            error={isUpdateLivestreaming ? Boolean(endTime.error) : false}
            helperText={isUpdateLivestreaming ? endTime.error : null}
            disabled={!(isUpdateLivestreaming || isCreateLiveStreaming)}
          />
        </Grid>
      </Grid>
      <div>
        <AppBar position="static" className={classes.appbar}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="simple tabs"
            variant="fullWidth"
            centered
          >
            <Tab
              label={intl.formatMessage({
                id: 'meeting_participants',
              })}
              index={0}
            />
            <Tab
              label={intl.formatMessage({
                id: 'livestreaming_viewers',
              })}
              index={1}
            />
          </Tabs>
        </AppBar>

        <div>
          <TabPanel value={tabIndex} index={0}>
            <AutocompleteTextField
              multiple={false}
              limitTags={1}
              id="schedule-livestreaming-groups"
              options={groups.data}
              getOptionLabel={(option) => option.name}
              value={group.value}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={handleGroupChange}
              disabled={!(isUpdateLivestreaming || isCreateLiveStreaming)}
              label={intl.formatMessage({
                id: 'group',
              })}
              placeholder={intl.formatMessage({
                id: 'select_a_group',
              })}
            />
            <AutocompleteTextField
              multiple
              limitTags={2}
              id="schedule-livestreaming-members"
              options={membersList}
              getOptionLabel={(option) => option.profile.name}
              value={members.value}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={handleMemberChange}
              disabled={!(isUpdateLivestreaming || isCreateLiveStreaming)}
              label={intl.formatMessage({
                id: 'participants',
              })}
            />
            <AutocompleteTextField
              multiple
              freeSolo
              id="schedule-livestreaming-external-members"
              options={[]}
              onChange={handleExternalMembersChange}
              value={externalMembers.value}
              disabled={!(isUpdateLivestreaming || isCreateLiveStreaming)}
              label={intl.formatMessage({
                id: 'external_members_emails',
              })}
              // placeholder={intl.formatMessage({
              //   id: 'enter_at_least_one_external_member',
              // })}
            />
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <AutocompleteTextField
              multiple={false}
              limitTags={1}
              id="schedule-livestreaming-viewing-groups"
              options={groups.data}
              getOptionLabel={(option) => option.name}
              value={livestreamingGroup.value}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={handleStreamingGroupChange}
              disabled={!(isUpdateLivestreaming || isCreateLiveStreaming)}
              label={intl.formatMessage({
                id: 'group',
              })}
              placeholder={intl.formatMessage({
                id: 'select_a_group',
              })}
            />
            <AutocompleteTextField
              multiple
              limitTags={2}
              id="schedule-livestreaming-viewers"
              options={membersList}
              getOptionLabel={(option) => option.profile.name}
              value={livestreamingViewers.value}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={handleLivestreamingViewersChange}
              disabled={!(isUpdateLivestreaming || isCreateLiveStreaming)}
              label={intl.formatMessage({
                id: 'livestreaming_viewers',
              })}
            />
            <AutocompleteTextField
              multiple
              freeSolo
              id="schedule-livestreaming-external-viewers"
              options={[]}
              onChange={handleLivestreamingExternalViewersChange}
              value={livestreamingExternalViewers.value}
              disabled={!(isUpdateLivestreaming || isCreateLiveStreaming)}
              label={intl.formatMessage({
                id: 'livestreaming_external_viewers_emails',
              })}
              // placeholder={intl.formatMessage({
              //   id: 'enter_at_least_one_external_member',
              // })}
            />
            {/* <Typography
              variant="body2"
              component="p"
              className={classes.streamerDescription}
            >
              {intl.formatMessage({
                id: 'create_livestreaming_viewers_note',
              })}
            </Typography> */}
          </TabPanel>
        </div>
      </div>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(ScheduleLiveStreamingModal));
