/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { AutocompleteChangeReason } from '@material-ui/lab';
import { ActionModal } from '../../../components/Modal';
import { TextField } from '../../../components/TextField';
import { IMembersState, fetchMembersAsync } from '../../members/members.slice';
import { IGroup, IGroupsState } from '../groups.slice';
import { useAppDispatch } from '../../../app/hooks';
import { IEditGroupData } from '../groups.api';
import { IUser } from '../../auth/auth.slice';
import { AutocompleteTextField } from '../../../components/AutocompleteTextField';
import { validateEmail } from '../../../helper/validation';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IEditGroupModalProps extends WithStyles<typeof styles> {
  open: boolean;
  selectGroup: IGroupsState;
  selectedGroup: IGroup;
  selectMember: IMembersState;
  allGroups: boolean;
  handleClose: () => void;
  handleEditGroup: (data: IEditGroupData) => void;
}

const EditGroupModal = ({
  classes,
  open,
  handleClose,
  handleEditGroup,
  selectGroup,
  selectedGroup,
  selectMember,
  allGroups,
}: IEditGroupModalProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (allGroups && selectedGroup?.parentId?._id) {
      dispatch(fetchMembersAsync({ id: selectedGroup.parentId._id }));
    }
  }, [selectedGroup]);

  useEffect(() => {
    handleClose();
    setName({
      error: false,
      value: '',
    });
    setDescription({
      error: false,
      value: '',
    });
    setMemberIds({
      error: false,
      value: [],
    });
    setExternalMembers({
      error: false,
      value: [],
    });
  }, [selectGroup.loading]);

  useEffect(() => {
    if (selectedGroup) {
      setName({
        value: selectedGroup.name,
        error: !selectedGroup.name,
      });
      setDescription({
        ...description,
        value: selectedGroup.description,
      });

      const members = [];
      for (const member1 of selectedGroup.members) {
        for (const member2 of selectMember.list.data) {
          if (member1.userId._id === member2._id) {
            members.push(member2);
          }
        }
      }
      setMemberIds({
        ...memberIds,
        value: members,
      });
      const externalMembersArr = [];
      for (const externalMember of selectedGroup.externalMembers) {
        externalMembersArr.push(externalMember.email);
      }
      setExternalMembers({
        ...externalMembers,
        value: externalMembersArr,
      });
    }
  }, [selectMember.list, selectedGroup]);

  const [name, setName] = useState({
    error: false,
    value: '',
  });
  const [description, setDescription] = useState({
    error: false,
    value: '',
  });

  interface IMemberIdState {
    error: boolean;
    value: IUser[];
  }
  interface IExternalMemberState {
    error: boolean;
    value: string[];
  }

  const [memberIds, setMemberIds] = useState<IMemberIdState>({
    error: false,
    value: [],
  });
  const [externalMembers, setExternalMembers] = useState<IExternalMemberState>({
    error: false,
    value: [],
  });

  const intl = useIntl();

  const _validate = () => {
    let isValidate = true;
    if (!name.value || name.error) {
      isValidate = false;
      setName({ ...name, error: true });
    }
    // if (!description.value || description.error) {
    //   isValidate = false;
    //   setDescription({ ...description, error: true });
    // }
    // if (memberIds.value.length === 0 || memberIds.error) {
    //   isValidate = false;
    //   setMemberIds({ ...memberIds, error: true });
    // }
    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const memberIdsArr = [];
      for (const member of memberIds.value) {
        if (member._id) memberIdsArr.push(member._id);
      }

      const groupData = {
        groupId: selectedGroup._id,
        name: name.value,
        description: description.value,
        members: memberIdsArr,
        externalMembers: externalMembers.value,
      };
      handleEditGroup(groupData);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    setName({
      value: e.target.value,
      error: !e.target.value,
    });
  };

  const descriptionOnChange = (e: { target: { value: string } }) => {
    setDescription({
      value: e.target.value,
      error: !e.target.value,
    });
  };

  const handleMembersChange = (
    event: ChangeEvent<unknown>,
    value: IUser[],
    reason: AutocompleteChangeReason
  ) => {
    setMemberIds({
      value,
      error: value.length === 0,
    });
  };

  const handleExternalMembersChange = (
    event: ChangeEvent<unknown>,
    value: string[],
    reason: AutocompleteChangeReason
  ) => {
    if (value.slice(-1)[0]) {
      if (validateEmail(value.slice(-1)[0])) {
        return;
      }
    }
    setExternalMembers({
      value,
      error: value.length === 0,
    });
  };

  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'edit_group',
      })}
      modalDescription={intl.formatMessage({
        id: 'edit_group_to_manage_meetings',
      })}
      id="edit-group-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
      actinButtonLabel={intl.formatMessage({
        id: 'continue',
      })}
    >
      <TextField
        id="edit-group-name"
        value={name.value}
        error={name.error}
        placeholder={intl.formatMessage({
          id: 'name',
        })}
        onChange={nameOnChange}
        required
        helperText={name.error && 'Please insert a name.'}
      />
      <TextField
        id="edit-group-description"
        value={description.value}
        error={description.error}
        placeholder={intl.formatMessage({
          id: 'description',
        })}
        onChange={descriptionOnChange}
        required
        helperText={description.error && 'Please insert a valid description.'}
      />
      <AutocompleteTextField
        multiple
        limitTags={2}
        id="edit-group-members"
        options={selectMember.list.data}
        getOptionLabel={(option) => option.profile.name}
        value={memberIds.value}
        onChange={handleMembersChange}
        label={intl.formatMessage({
          id: 'members',
        })}
        placeholder={intl.formatMessage({
          id: 'select_at_least_one_member',
        })}
        error={memberIds.error}
      />
      <AutocompleteTextField
        multiple
        freeSolo
        id="edit-group-external-members"
        options={[]}
        onChange={handleExternalMembersChange}
        value={externalMembers.value}
        label={intl.formatMessage({
          id: 'external_members',
        })}
        placeholder={intl.formatMessage({
          id: 'enter_at_least_one_external_member',
        })}
        error={externalMembers.error}
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(EditGroupModal));
