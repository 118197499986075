/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';
import {
  Theme,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { formatInmmss } from '../../helper/calendar';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      padding: theme.spacing(1),
      '& .MuiToolbar-gutters': {
        paddingLeft: theme.spacing(2),
      },
      '& .MuiTableCell-body': {
        color: theme.palette.secondary.main,
        backgroundColor: '#fff',
      },
    },
  });
};

export interface RowData {
  name: string;
  created: string;
  destroyed: string;
  participants: number;
  duration: number;
}

export interface ITableProps extends WithStyles<typeof styles> {
  data: RowData[];
}

const Datatable = ({ data }: ITableProps) => {
  const intl = useIntl();

  const headers = [
    intl.formatMessage({
      id: 'action',
    }),
    intl.formatMessage({
      id: 'created',
    }),
    intl.formatMessage({
      id: 'destroyed',
    }),
    intl.formatMessage({
      id: 'participants',
    }),
    intl.formatMessage({
      id: 'duration',
    }),
  ];

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell align="right" key={header}>
                {header.toUpperCase()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((emp, index) => (
            <TableRow key={index}>
              <TableCell align="right" key="action">
                {emp.name}
              </TableCell>
              <TableCell align="right" key="created">
                {emp.created
                  ? moment(new Date(emp.created)).format('YYYY-MM-DD HH:mm:ss')
                  : 'inprogress'}
              </TableCell>
              <TableCell align="right" key="destroyed">
                {emp.destroyed
                  ? moment(new Date(emp.destroyed)).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                  : 'inprogress'}
              </TableCell>
              <TableCell align="right" key="participants">
                {emp.participants}
              </TableCell>
              <TableCell align="right" key="duration">
                {formatInmmss(emp.duration)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default memo(withStyles(styles)(Datatable));
