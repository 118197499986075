import { AxiosResponse } from 'axios';
import api from '../../app/axios';
import { IRequestParams } from '../../components/types';

export interface IGetGroupArgs extends IRequestParams {
  id?: string;
}

export interface IAddGroupData {
  name: string;
  description: string;
  members: string[];
  externalMembers: string[];
  customerId?: string;
  nextApiCallType?: string;
}

export interface IEditGroupData {
  name: string;
  description: string;
  groupId: string;
  members: string[];
  customerId?: string;
  nextApiCallType?: string;
}

export interface IEditAnyGroupData extends IEditGroupData {
  customerId: string;
  nextApiCallType: string;
}

export interface IDeleteGroupData {
  id: string;
  ids?: string[];
  customerId?: string;
  nextApiCallType?: string;
}

export const getGroupsApi = (data: IGetGroupArgs): Promise<AxiosResponse> => {
  const { id, ...filteredData } = data;
  if (id) {
    return api.post(`group/read/${id}`, filteredData);
  }
  return api.post(`group/read`, data);
};

export const getAllGroupsApi = (
  data: IGetGroupArgs
): Promise<AxiosResponse> => {
  return api.post('group/readall', data);
};

export const addGroupApi = (data: IAddGroupData): Promise<AxiosResponse> => {
  // return api.post(`group/create`, data);
  const { name, description, members, externalMembers } = data;
  if (data?.customerId) {
    return api.post(`group/create/${data.customerId}`, {
      name,
      description,
      members,
      externalMembers,
    });
  }
  return api.post(`group/create`, data);
};

export const deleteGroupApi = (id: string): Promise<AxiosResponse> => {
  return api.delete(`group/destroy/${id}`);
};

export const deleteGroupsApi = (ids: string[]): Promise<AxiosResponse> => {
  return api.post(`group/destroy`, { ids });
};

export const deleteAnyGroupApi = (id: string): Promise<AxiosResponse> => {
  return api.delete(`group/destroyany/${id}`);
};

export const deleteAnyGroupsApi = (ids: string[]): Promise<AxiosResponse> => {
  return api.post(`group/destroyany`, { ids });
};

export const editGroupApi = (data: IEditGroupData): Promise<AxiosResponse> => {
  return api.post(`group/update`, data);
};

export const editAnyGroupApi = (
  data: IEditGroupData
): Promise<AxiosResponse> => {
  return api.post(`group/updateany`, data);
};

export const destroyGroupMemberApi = (id: string): Promise<AxiosResponse> => {
  return api.delete(`group/destroymember/${id}`);
};

export const destroyAnyGroupMemberApi = (
  id: string
): Promise<AxiosResponse> => {
  return api.delete(`group/destroymemberany/${id}`);
};
