import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { MaxWidth } from '../types';
import BasicModal, { IModalProps } from './BasicModal';
import BasicButton from '../Button/BasicButton';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      // margin: theme.spacing(1),
      display: 'flex',
    },
    closeButton: {
      position: 'absolute',
      right: '0.5em',
      top: '0.5em',
    },
    cancelButton: {
      marginRight: theme.spacing(1),
    },
  });
};

export interface IActionModalProps
  extends WithStyles<IModalProps & typeof styles> {
  id?: string;
  open: boolean;
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  modalTitle?: string;
  selectedItem?: string;
  modalDescription?: string;
  fullWidth?: boolean;
  maxWidth?: MaxWidth;
  isSingleButton?: boolean;
  actinButtonLabel?: string;
  actinButtonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  disableBackdropClick?: boolean;
  actionButtonDisabled?: boolean;
}

export const ActionModal: React.FC<IActionModalProps> = ({
  classes,
  id,
  open,
  modalTitle,
  selectedItem,
  modalDescription,
  children,
  handleClose,
  fullWidth = true,
  maxWidth = 'sm',
  actinButtonLabel = 'Continue',
  actinButtonOnClick,
  isSingleButton = false,
  disableBackdropClick = false,
  actionButtonDisabled = false,
}: IActionModalProps) => {
  const intl = useIntl();

  return (
    <BasicModal
      id={id}
      handleClose={handleClose}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      modalTitle={modalTitle}
      selectedItem={selectedItem}
      modalDescription={modalDescription}
      disableBackdropClick={disableBackdropClick}
      actionButtons={
        <div className={classes.root}>
          {!isSingleButton && (
            <BasicButton
              label={intl.formatMessage({
                id: 'cancel',
              })}
              id={`${id}-action-button`}
              onClick={handleClose}
              color="primary"
              customStyles={classes.cancelButton}
            />
          )}
          <BasicButton
            label={actinButtonLabel}
            id={`${id}-action-button-2`}
            variant="contained"
            color="primary"
            onClick={actinButtonOnClick}
            disabled={actionButtonDisabled}
          />
        </div>
      }
    >
      {children}
    </BasicModal>
  );
};

export default withStyles(styles)(ActionModal);
