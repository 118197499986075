/* eslint-disable @typescript-eslint/no-empty-function */
import React, { memo, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Theme } from '@material-ui/core';
import clsx from 'clsx';
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
  SelectableRows,
} from 'mui-datatables';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  withStyles,
  WithStyles,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { IRequestParams } from '../types';
import { Checkbox } from '../Checkbox';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      position: 'relative',
      height: '100%',
      '& >div:nth-child(1)': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& >div:nth-child(1)': {
          flex: 'unset',
          '& >div:nth-child(1)': {
            padding: '8px 0px !important',
          },
        },
        '& >div:nth-child(3)': {
          flexGrow: 1,
        },
      },
      '& .MuiToolbar-gutters': {
        paddingLeft: theme.spacing(2),
        display: 'flex',
        minHeight: 64,
        [theme.breakpoints.down('xs')]: {
          minHeight: 'unset',
          padding: 0,
        },
      },
      '& .MuiToolbar-root': {
        '& .Mui-disabled': {
          '& .MuiSvgIcon-root': {
            fill: 'currentColor',
          },
        },
        '& .MuiSvgIcon-root': {
          fill: theme.palette.secondary.main,
          [theme.breakpoints.down('xs')]: {
            width: '20px',
            height: '20px',
          },
        },
        '& div:nth-child(2)': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          fontWeight: '600',
          [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
            '& .MuiIconButton-root': {
              // padding: 10,
            },
          },
        },
      },
      '& .MuiTableCell-head': {
        fontSize: '16px',
        fontWeight: 600,
      },
      '& .MuiTableCell-body': {
        color: theme.palette.secondary.main,
        backgroundColor: '#fff',
      },
      '& .MuiTableCell-footer': {
        border: 'none',
        [theme.breakpoints.down('xs')]: {
          padding: 0,
          '& .MuiToolbar-gutters': {
            padding: 0,
          },
          '& .MuiIconButton-root': {
            padding: '12px 5px',
          },
        },
        '& p:nth-child(2)': {
          color: theme.palette.grey[600],
        },
        '& .MuiTablePagination-caption': {
          fontSize: '16px',
          [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
          },
        },
        '& .MuiSelect-select.MuiSelect-select': {
          fontSize: '16px',
          paddingBottom: 0,
          [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
          },
        },
      },
      '& .MUIDataTableToolbar-actions': {
        display: 'flex',
      },
    },
    denseControlWrapper: {
      position: 'absolute',
      bottom: '15px',
      '& .MuiFormControlLabel-label': {
        color: theme.palette.grey[600],
      },
    },
    selectCheckboxNoFilter: {
      right: '0 !important',
    },
    selectCheckboxWrapper: {
      position: 'absolute',
      top: 11,
      right: 110,
      '& .MuiCheckbox-colorPrimary': {
        color: theme.palette.secondary.main,
      },
      '& .MuiCheckbox-colorPrimary.Mui-checked': {
        color: theme.palette.primary.main,
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.secondary.main,
        fontWeight: '600',
      },
      [theme.breakpoints.down('xs')]: {
        top: 11,
        right: 70,
        '& .MuiFormControlLabel-label': {
          fontSize: 14,
        },
        '& .MuiSvgIcon-root': {
          width: '20px',
          height: '20px',
        },
      },
    },
    titleWrapper: {
      fontSize: theme.typography.button.fontSize,
      fontWeight: theme.typography.button.fontWeight,
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },
  });
};

export interface ITableProps extends WithStyles<typeof styles> {
  id: string;
  columns: MUIDataTableColumnDef[];
  data: Array<any | number[] | string[]>;
  options?: MUIDataTableOptions | undefined;
  title: string | React.ReactNode;
  pagination?: boolean;
  customStyles?: any;
  serverSide?: boolean;
  filter?: boolean;
  search?: boolean;
  count?: number;
  selectableRows?: boolean;
  dataRefreshFunc?: ({ limit, offset, sortOrder }: IRequestParams) => void;
  selectableRowsFunc?: React.Dispatch<React.SetStateAction<any[]>>;
  openDeleteModalFunc?: React.Dispatch<React.SetStateAction<boolean>>;
  denseControl?: boolean;
  components?: any;
}

const Table = ({
  id,
  classes,
  columns,
  data,
  options,
  title,
  pagination = false,
  serverSide = false,
  filter = false,
  search = false,
  count = 0,
  dataRefreshFunc = () => {},
  selectableRowsFunc = () => {},
  openDeleteModalFunc = () => {},
  customStyles,
  denseControl = true,
  components,
  selectableRows = false,
}: ITableProps) => {
  let timestamp = 0;
  const tableMetadata: IRequestParams = {};
  const intl = useIntl();
  const tableWrapper = useRef<HTMLDivElement>(null);

  const [isDense, setIsDense] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (tableWrapper.current) {
      setWidth(tableWrapper.current.offsetWidth);
    }
  }, []);

  return (
    <div
      id={id}
      ref={tableWrapper}
      className={clsx(classes.container, customStyles && customStyles)}
    >
      <MUIDataTable
        title={<div className={classes.titleWrapper}>{title}</div>}
        columns={columns}
        data={data}
        components={components}
        options={{
          search,
          responsive: 'standard',
          filter,
          filterType: 'textField',
          download: false,
          print: false,
          selectableRows: isMultiSelect ? 'multiple' : 'none',
          elevation: 0,
          viewColumns: false,
          pagination,
          serverSide,
          customToolbar: () => {
            if (!filter) return null;
            return (
              <div>
                {intl.formatMessage({
                  id: 'filter',
                })}
              </div>
            );
          },
          count,
          setTableProps: () => {
            return {
              size: isDense ? 'small' : 'medium',
            };
          },
          // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
          //   console.log(rowsSelectedData, allRows, rowsSelected);
          //   // this.setState({ rowsSelected: rowsSelected });
          // },
          onRowsDelete: (rowsDeleted) => {
            const deletedRows = [];
            if (rowsDeleted && rowsDeleted.data) {
              for (let index = 0; index < rowsDeleted.data.length; index += 1) {
                deletedRows.push(data[rowsDeleted.data[index].index]);
              }
              selectableRowsFunc(deletedRows);
              openDeleteModalFunc(true);
            }
            return false;
          },
          rowsPerPageOptions: [5, 10, 30, 100],
          onTableChange: (action, tableState) => {
            // console.log(action, tableState);

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            const filters: { name: string; value: string }[] = [];

            for (let index = 0; index < tableState.columns.length; index += 1) {
              const columnName = tableState.columns[index].name;
              if (tableState.filterList[index][0]) {
                const columnValue = tableState.filterList[index][0];
                filters.push({ name: columnName, value: columnValue });
              }
            }

            const dataRefreshFuncCaller = () => {
              if (new Date().getTime() - timestamp > 500) {
                dataRefreshFunc(tableMetadata);
                timestamp = 100000000000000;
              }
            };

            timestamp = new Date().getTime();
            tableMetadata.limit = tableState.rowsPerPage;
            tableMetadata.offset = tableState.page * tableState.rowsPerPage;
            if (Object.keys(tableState.sortOrder).length !== 0) {
              tableMetadata.sortOrder = tableState.sortOrder;
            }
            tableMetadata.search = tableState.searchText
              ? tableState.searchText
              : '';
            tableMetadata.filters = filters;

            switch (action) {
              case 'changeRowsPerPage':
              case 'changePage':
              case 'sort':
              case 'search':
                dataRefreshFunc(tableMetadata);
                timestamp = 100000000000000;
                break;
              case 'filterChange':
              case 'resetFilters':
                setTimeout(dataRefreshFuncCaller, 1000);
                break;
              default:
              // console.log('action not handled.');
            }
          },
          textLabels: {
            body: {
              noMatch: intl.formatMessage({
                id: 'no_matching_record',
              }),
              toolTip: intl.formatMessage({
                id: 'sort',
              }),
              columnHeaderTooltip: (column) => `Sort for ${column.label}`,
            },
            pagination: {
              next: intl.formatMessage({
                id: 'next_page',
              }),
              previous: intl.formatMessage({
                id: 'previous_page',
              }),
              rowsPerPage: intl.formatMessage({
                id: 'rows_per_page',
              }),
              displayRows: intl.formatMessage({
                id: 'of',
              }),
            },
            toolbar: {
              search: intl.formatMessage({
                id: 'search',
              }),
              downloadCsv: intl.formatMessage({
                id: 'download_csv',
              }),
              print: intl.formatMessage({
                id: 'print',
              }),
              viewColumns: intl.formatMessage({
                id: 'view_columns',
              }),
              filterTable: intl.formatMessage({
                id: 'filter_table',
              }),
            },
            filter: {
              all: intl.formatMessage({
                id: 'all',
              }),
              title: intl.formatMessage({
                id: 'filters',
              }),
              reset: intl.formatMessage({
                id: 'reset',
              }),
            },
            viewColumns: {
              title: intl.formatMessage({
                id: 'show_columns',
              }),
              titleAria: intl.formatMessage({
                id: 'show_hide_table_columns',
              }),
            },
            selectedRows: {
              text: intl.formatMessage({
                id: 'rows_selected',
              }),
              delete: intl.formatMessage({
                id: 'delete',
              }),
              deleteAria: intl.formatMessage({
                id: 'delete_selected_rows',
              }),
            },
          },
          ...options,
        }}
      />
      {selectableRows && (
        <div
          className={clsx(
            classes.selectCheckboxWrapper,
            !filter && classes.selectCheckboxNoFilter
          )}
        >
          <Checkbox
            id="table-select-checkbox"
            checked={isMultiSelect}
            onChange={() => setIsMultiSelect(!isMultiSelect)}
            label={intl.formatMessage({
              id: 'select',
            })}
          />
        </div>
      )}
      {denseControl && !isMobile && (
        <div className={classes.denseControlWrapper}>
          <FormControlLabel
            control={
              <Switch
                checked={isDense}
                onChange={() => setIsDense(!isDense)}
                color="primary"
              />
            }
            label={intl.formatMessage({
              id: width < 600 ? 'dense' : 'dense_padding',
            })}
          />
        </div>
      )}
    </div>
  );
};

export default memo(withStyles(styles)(Table));
