import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { withStyles, WithStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GroupIcon from '@material-ui/icons/Group';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { Typography } from '../../../components/Typography';
import { Button, IconOnlyButton } from '../../../components/Button';
import styles from './meetingCard.styles';
import { MeetingInfoModal } from '../../meetings/components';
import { MeetingCard } from '../../../components/MeetingCard';

interface IMeetingCardProps extends WithStyles<typeof styles> {
  meeting: any;
  openEditMeetingModal: (data: any) => void;
  key?: any;
}

const RecentMeetingCard = ({
  classes,
  meeting,
  openEditMeetingModal,
  key,
}: IMeetingCardProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isMeetingInfoOpen, setIsMeetingInfoOpen] = useState(false);

  const InfoItem = ({ icon, text }: any) => {
    return (
      <div className={classes.infoItem}>
        {icon}
        <Typography variant="body1" className={classes.infoItemText}>
          {text}
        </Typography>
      </div>
    );
  };

  return (
    <MeetingCard customStyles={classes.meetingCard} key={key}>
      <Typography variant="caption" component="h3" className={classes.title}>
        {meeting?.name}
      </Typography>
      <div className={classes.meetingInfoWrapper}>
        <InfoItem
          icon={<ScheduleIcon />}
          text={`${intl.formatMessage({
            id: 'time',
          })} : ${moment(meeting?.beginTime).format('YYYY/MM/DD hh:mm A')}`}
        />
        <div className={classes.largeItem}>
          <InfoItem
            icon={<PermContactCalendarIcon />}
            text={`${intl.formatMessage({
              id: 'host',
            })} : ${meeting?.host?.profile?.name}`}
          />
        </div>
        <InfoItem
          icon={<GroupIcon />}
          text={`${intl.formatMessage({
            id: 'meeting_id',
          })} : ${meeting?.id}`}
        />
      </div>
      <div className={classes.buttonWrapper}>
        {isMobile ? (
          <IconOnlyButton onClick={() => setIsMeetingInfoOpen(true)}>
            <VisibilityIcon />
          </IconOnlyButton>
        ) : (
          <Button
            variant="outlined"
            label={intl.formatMessage({
              id: 'view_details',
            })}
            onClick={() => setIsMeetingInfoOpen(true)}
          />
        )}
        <Button
          variant="contained"
          color="primary"
          label={intl.formatMessage({
            id: 'reschedule',
          })}
          onClick={() => openEditMeetingModal(meeting)}
        />
      </div>
      {isMeetingInfoOpen && (
        <MeetingInfoModal
          open={isMeetingInfoOpen}
          handleClose={() => setIsMeetingInfoOpen(false)}
          meeting={meeting}
        />
      )}
    </MeetingCard>
  );
};

RecentMeetingCard.defaultProps = {
  key: '',
};

export default memo(withStyles(styles)(RecentMeetingCard));
