export const environment = process.env.REACT_APP_ENV || 'development';
export const version = process.env.REACT_APP_VERSION || '1.0.0';

export const apiBaseUrl =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:8081/v1';

export const jitsiDomain =
  process.env.REACT_APP_JITSI_DOMAIN || 'devmeet2.meetrix.io';

export const webBaseUrl =
  process.env.REACT_APP_WEB_BASE_URL || 'https://meetingmanager.meetrix.io';

export const externalAPI =
  process.env.REACT_APP_EXTERNAL_API_SRC ||
  'https://devmeet2.meetrix.io/external_api.js';

export const replaceRoomNameWithTitle =
  process.env.REPLACE_ROOM_NAME_WITH_TITLE || true;
