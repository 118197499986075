import React, { memo } from 'react';
import { Redirect } from 'react-router-dom';
import { createStyles } from '@material-ui/core/styles';
import { ApmRoute } from '@elastic/apm-rum-react';
import {
  OutlinedInputProps,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';

import clsx from 'clsx';
import { Footer } from '../components/layout';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getProfileAsync, selectAuth } from '../features/auth/auth.slice';
import { AppBar } from '../features/appBar';
import { getToken } from '../helper/localStorage';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      width: '100%',
      backgroundColor: theme.palette.grey[300],
      display: 'flex',
      flexDirection: 'column',
      '& .max-width-responsive': {
        maxWidth: 2500, // set max-width for all layouts
      },
    },
    bodyWrapper: {
      // minHeight: 'calc(100vh - 64px - 35px - 4vh)', // fullwidth - navbar - footer - bodyPadding-TopBottom
      flexGrow: 1,
      width: '100%',
      overflow: 'auto',
      padding: '36px 60px',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        padding: '24px 30px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: 24,
      },
    },
    maxWidth: {
      display: 'flex',
      width: '100%',
    },
    fullScreenBodyWrapper: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
  });
};
interface IRouteWrapper extends WithStyles<OutlinedInputProps & typeof styles> {
  Component: any;
  path: string;
  exact: boolean;
  isPrivate: boolean;
  //   hasLoader: boolean;
  hasNavbar?: boolean;
  hasFooter?: boolean;
}

const RouteWrapper = ({
  Component,
  classes,
  isPrivate = false,
  //   hasLoader = false,
  hasNavbar = false,
  hasFooter = false,
  ...rest
}: IRouteWrapper) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated, loading } = useAppSelector(selectAuth);
  const token = getToken();

  if (!loading && isPrivate && !isAuthenticated) {
    dispatch(getProfileAsync(null));
  }

  if (!loading && !token && isPrivate && !isAuthenticated) {
    return <Redirect to="/signin" />;
  }

  // if (!isPrivate && isAuthenticated) {
  //   return <Redirect to="/" />;
  // }

  return (
    <ApmRoute
      {...rest}
      component={(props: JSX.IntrinsicAttributes) => (
        <div className={classes.root}>
          {hasNavbar && <AppBar />}
          <div
            className={
              hasNavbar && hasFooter
                ? classes.bodyWrapper
                : classes.fullScreenBodyWrapper
            }
          >
            <div className={clsx(classes.maxWidth, 'max-width-responsive')}>
              <Component {...props} />
            </div>
          </div>
          {hasFooter && <Footer />}
        </div>
      )}
    />
  );
};

RouteWrapper.defaultProps = {
  hasNavbar: false,
  hasFooter: false,
};

export default memo(withStyles(styles)(RouteWrapper));
